<template>
    <transition name="slide">
      <CCard>
        <CCardBody>
          <CButton color="primary" @click="create()"
            ><CIcon name="cil-plus" /> Create New Integration</CButton
          >
          <br />
          <br />
          <br />
  
          <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
  
          <CDataTable
            :items="items"
            :fields="fields"
            :items-per-page="50"
            hover
            pagination
          >
          <template #name="{ item  }">
              <td style="width: 40%">
                <strong>{{ item.name }}</strong>
              </td>
            </template>
            <template #actions="{ item }">
              <td style="width: 400px;" >
                <CButton style="margin-left:10px" color="primary" @click="edit(item.id)"
                  ><CIcon name="cil-pencil" /> Edit</CButton
                >
                <CButton
                  color="danger"
                  @click="
                    warningModal = true;
                    integration = item;
                  "
                  ><CIcon name="cil-trash" /> Delete</CButton
                >
              </td>
            </template>
          </CDataTable>
          <CModal
            v-model="integration.id"
            title="Are you sure to delete this integration?"
            color="danger"
            :show.sync="warningModal"
            @update:show="closeModal"
          >
            <CRow>
              <CCol col="6">
                <CInput label="Name" v-model="integration.name" disabled />
              </CCol>
            </CRow>
          </CModal>
        </CCardBody>
      </CCard>
    </transition>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "Integrations",
  
    data: () => {
      return {
        // use uuuid
        emptyGuid: "00000000-0000-0000-0000-000000000000",
        fields: ["name",  "actions"],
  
        warningModal: false,
        integration: [],
  
        // Alert
        alertType: "danger",
        message: null,
  
        // List
        items: [],
      };
    },
    computed: {},
    methods: {
      getBadge(status) {
        switch (status) {
          case true:
            return "success";
          case false:
            return "secondary";
          default:
            return "primary";
        }
      },
  
      closeModal(status, evt, accept) {
        if (accept) {
          this.delete(this.integration.id);
        }
      },
  
      edit(id) {
        this.$router.push({ path: `integrations/create/${id}` });
      },
      delete(id) {
        let self = this;
        axios
          .delete(this.$apiAdress + "/v1/Integration/" + id)
  
          .then(function () {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully deleted integration.";
            self.getList();
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      create() {
        this.$router.push({ path: "/integrations/create/" + this.emptyGuid });
      },
  
      getList() {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/Integration")
          .then(function (response) {
            self.items = [];
            response.data.map(item=>{
                self.items.push(item);
            })
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
    },
    mounted: function () {
      this.getList();
    },
  };
  </script>
  
  <style scoped>
  .card-body >>> table > tbody > tr > td {
    cursor: pointer;
  }
  </style>
  